import React from "react";
import { Icon, Image } from "semantic-ui-react";

import styles from './footer.module.scss'
import {SidebarOpener} from './../../../components/Sidebar/SidebarOpener';


function Footer() {
	return (
		<div className={styles["footer__container"]}>
			<SiteLinks />
			<SocialMediaLinks />
		</div>
	)
}

function SocialMediaLinks() {
	return (
		<div className={styles["footer__social__container"]}>
			<div className={styles["footer__socialMedia__link"]}>
				<a target="_blank" href="https://www.linkedin.com/company/catalistmarkets/" rel="noopener">
					<Icon name="linkedin" size="big" style={{ color: 'white'}}/>
				</a>
				<a target="_blank" href='https://www.facebook.com/CatalistMarkets' rel="noopener">
					<Icon name="facebook f" size="big" style={{ color: 'white'}}/>
				</a>
				<a target="_blank" href='https://www.instagram.com/catalistmarkets' rel="noopener">
					<Icon name="instagram" size="big" style={{ color: 'white'}}/>
				</a>
			</div>
			<div className={styles["footer__catalist__white-logo__container"]}>
				<a href="/">
					<Image src="https://cdn.catalist.co.nz/v2/Catalist_Logo_White.png" size='small'  className={styles["footer__catalist__white-logo"]}/>
				</a>
			</div>
		</div>
	)
}

function SiteLinks() {
	return (
		<>
			<div className={styles["site__links__container"]}>
				<a href="/marketplace" className={styles["site__links__text"]}>Marketplace</a>
				<a href="/Schedule" className={styles["site__links__text"]}>Auction schedule</a>
				<a href="/investors" className={styles["site__links__text"]}>Investors</a>
				<a href="/business-solutions" className={styles["site__links__text"]}>Business solutions</a>
				<SidebarOpener 
					linkText={'Join'}
					isHyperlink={true} 
					className={styles["site__links__text"]}
				/>
			</div>

			<div className={styles["site__links__container"]}>
				<a href="/About" className={styles["site__links__text"]}>About</a>
				<a href="/About#our-team" className={styles["site__links__text"]}>Meet the team</a>
				<a href="/MarketplaceMechanics" className={styles["site__links__text"]}>Marketplace mechanics</a>
				<a href="/info/blog" className={styles["site__links__text"]}>Blog</a>
				<a href="/Media" className={styles["site__links__text"]}>Media</a>
			</div>

			<div className={styles["site__links__container"]}>
				<a href="/Contact" className={styles["site__links__text"]}>Contact</a>
				<a href="/Documentation" className={styles["site__links__text"]}>Documentation</a>
				<a href="https://cdn.catalist.co.nz/Documents/Catalist+Privacy+Policy.pdf" target="_blank" className={styles["site__links__text"]}>Privacy policy</a>
				<a href="/FAQ" className={styles["site__links__text"]}>FAQ</a>
				<a href="/Fees" className={styles["site__links__text"]}>Fees</a>
				<div className={styles["footer__copyright__container"]}>
					<p className={styles["footer__legal"]}> © Catalist Markets Limited {(new Date()).getFullYear()}. All rights reserved. </p>
				</div>
			</div>
		</>
	)
}

export default Footer;
