// SEO is to be tailored to each specific page so they have unique titles and descriptions which help promote our standing in search rankings.

// Default SEO tags - call them default rather than standard
const defaultSEO = { title: "Catalist | The stock exchange for New Zealand's growth economy", description: "The simplest way for investors and businesses to connect and grow with unrivalled access to NZ's growth marketplace.", keywords: 'Growth stock exchange, investors, SMEs, share marketplace, capital markets, trading, shares, startups, NZ', }

// Invest
const investSEO = { title: 'Invest - Retail, Wholesale, Angel | Catalist', description: 'Diversify your investment portfolio with the stock exchange for growing NZ businesses.', keywords: 'Investment, Growth Businesses, portfolio diversification, NZ' }

// Market
const marketSEO = { title: 'Investment marketplace - Public, Wholesale, Angel | Catalist', description: "The investment marketplace where investors can view investments in a diverse portfolio of New Zealand's growth businesses, SMEs and startups.", keywords: 'Listed businesses, investments, SMEs, share marketplace, retail investors, wholesale investors, angel investors, NZ' }

// For Investors
const investorsSEO = { title: 'Investors | Catalist', description: 'Investors have new investment opportunities to diversify your investment portfolio with the stock exchange for growing NZ businesses. Open to all investors.', keywords: 'Investment, Growth Businesses, portfolio diversification, NZ' }

// Blogs
const blogsSEO = { title: 'Blog | Catalist', description: "Information, tips and investing news from Catalist and the marketplace for New Zealand's growth investments.", keywords: 'Blog, news, Growth Businesses, investment, capital raise, share trading, NZ' }

// Contact 
const contactSEO = { title: 'Contact Us | Catalist', description: 'Any suggestions, questions or feedback? Reach us here and we will be happy to help.', keywords: 'customer service' }

// FAQ
const faqSEO = { title: 'Frequently Asked Questions | Catalist', description: 'The answers to some of our most frequently asked questions about investments, investing and listing a business.', keywords: 'FAQs, customer service, How to' }

// Fees
const feesSEO = { title: 'Fees | Catalist', description: 'A description of the fees you might need to pay to buy or sell investments in our growth marketplace.', keywords: 'Fees, costs' }

// Documentation 
const documentationSEO = { title: 'Documentation | Catalist', description: 'This is the one stop shop for all you need to know about investing, listing and other information related to our growth investment marketplace.', keywords: 'Terms and conditions, policies, guidance, documentation' }

// About 
const aboutUsSEO = { title: 'About Us | Catalist', description: 'Welcome to Catalist New Zealand’s only licensed and regulated stock exchange designed to unlock new investment opportunities.', keywords: 'about, team, about us, about Catalist, investing, invest, NZ' }

// Sign up
const signUpSEO = { title: 'Sign up | Catalist', description: 'Sign up for a Catalist Investor account here and access the market place with investment offerings from a variety of New Zealand growth businesses.', keywords: 'sign up'}

// Marketplace mechanics
const marketplaceMechanicsSEO = { title: 'Marketplace Mechanics | Catalist', description: 'Learn how auctions work and the mechanics of the marketplace. Understand the types and priorities of bids (to buy) and offers (to sell).', url: 'https://www.catalist.co.nz/MarketplaceMechanics', keywords: 'Marketplace mechanics, priority of investment, auction algorithm'}

// Media
const mediaSEO = { title: 'Media | Catalist', description: 'Catalist media coverage across articles, podcasts and video.', url: 'https://www.catalist.co.nz/Media', keywords: 'Media, press, coverage, NZ'}

// Calendar
const calendarSEO = { title: 'Auction schedule | Catalist', description: 'Catalist upcoming scheduled auctions.', url: 'https://www.catalist.co.nz/Schedule', keywords: 'schedule, auction, calendar, dates, upcoming, future'}

const businessSolutionsSEO = { title: 'Business solutions - SME business capital-markets | Catalist', description: 'For businesses wanting to raise capital, manage investments and liquidity, manage shareholders and registry management, pre-emptive rights, or nominee services.', keywords: 'SMEs, Growth Businesses, corporate finance, investment, capital raise, secondary share trading, registry, pre-emptive rights, funds management, nominee, NZ' }

const forBusinessesSEO = { title: 'Businesses - Trade & Raise Capital | Catalist', description: 'Unlock the power of tradable shares and stimulate new avenues of investments and raising capital for your business through Catalist marketplace.', keywords: 'SMEs, Growth Businesses, investment, capital raise, secondary share trading, liquidity, registry, pre-emptive rights, NZ' } 

const forFundsSEO = { title: 'Funds - Investor onboarding, registry, payments | Catalist', description: "Give your investors and limited partners an efficient and professional experience when investing in your fund using Catalist's fund administration and investor management tools.", keywords: 'VC Funds, managed funds, investor management tools, SME funds, growth business funds, registry, investor onboarding, AML, wholesale verification, capital calls tool, NZ' } 

const forInvestmentGroupsSEO = { title: 'Investment groups - Portfolio & transaction management | Catalist', description: 'Professional and efficient management of investment groups from onboarding to investment, syndication, investment reporting and end-to-end investor management tools.', keywords: 'Angel investing, investor group administration, portfolio management, share sale tools, electronic investment platform, venture capital investing, NZ' }

const forAdvisorsSEO = { title: 'Advisers & Lawyers - Investment management platform | Catalist', description: 'Efficiently manage capital investment into your customers or other securities transactions such as secondary market liquidity, pre-emptive rights and financial markets regulatory compliance.', keywords: 'Capital raising adviser tools, capital raise administration, securities compliance, SME business adviser tools, growth business adviser tools, share trading, liquidity tools, registry, deal room, NZ' }

const nomineeCompanyServicesSEO = { title: "Nominee service - NZ's best nominee company service | Catalist", description: 'Improve your capital structure and shareholder management with an efficient nominee company service integrated into our investment management platform.', keywords: 'Nominee company, shareholder management, trustee shareholder, investment regulation compliance, NZ' } 

export {
  defaultSEO,
  marketSEO,
  investorsSEO,
  blogsSEO,
  contactSEO,
  faqSEO,
  feesSEO,
  documentationSEO,
  aboutUsSEO,
  signUpSEO,
  investSEO,
  marketplaceMechanicsSEO,
  mediaSEO,
  calendarSEO,
  businessSolutionsSEO,
  forBusinessesSEO,
  forFundsSEO,
  forInvestmentGroupsSEO,
  forAdvisorsSEO,
  nomineeCompanyServicesSEO
}